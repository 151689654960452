export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'bgSecondary',
  color: 'white',
  border: 'none',
  placeholder: '...',
  _placeholder: { color: 'white' },
  _focus: { borderColor: 'white' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0 0 0',
};

export const CHECKBOX_PROPS = {
  fontSize: 'md',
  color: 'secondaryColorText',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'white',
  fontFamily: 'kern',
  fontWeight: '400',
  fontSize: 'md',
  _focus: { borderColor: 'white' },
};
